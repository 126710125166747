import React, { Fragment } from "react"
import { compose } from "recompose"
import Layout from "../components/layout"
import { AuthUserContext, withAuthorization } from "../components/Session"
import { Link } from "gatsby"

function LicenseInfo(props) {
  const user = props.user
  const customerInfo = props.customerInfo

  const product = customerInfo.product

  let licenseId = null
  let activationPassword = null
  let link = null
  switch (product) {
    case "hass":
      licenseId = user.licenseID
      activationPassword = user.activationPassword
      link =
        "https://storage.googleapis.com/hrs-systems-public/downloads/HASS/Setup.exe"
      break
    case "hassHouse":
      licenseId = user.hassHouseLicenseID
      activationPassword = user.hassHouseActivationPassword
      link =
        "https://storage.googleapis.com/hrs-systems-public/downloads/hh2020/Setup.exe"
      break
    case "coosa":
      licenseId = user.coosaLicenseID
      activationPassword = user.coosaActivationPassword
      link =
        "https://storage.googleapis.com/hrs-systems-public/downloads/cs2020/Setup.exe"
      break

    default:
      licenseId = ""
      activationPassword = ""
      link = ""

  }

  if (customerInfo) {
    return (
      <Fragment>
        <p>License ID: {licenseId}</p>
        <p>Activation Password: {activationPassword}</p>
        <div className="btn-box">
          <a className="default-btn" href={link}>
            <i className="bx bxs-download"></i>
            Download Now
            <span></span>
          </a>
        </div>
      </Fragment>
    )
  }
  return null
}

class ThankYouPageBase extends React.Component {

  render() {
    return (
      <Fragment>
        <AuthUserContext.Consumer>
          {authUser => (
            <div>
              <h1>Thank you!</h1>
              <p>
                Your payment was a success, please go to your account page to
                view your license information and download the software:
              </p>
              <div className="btn-box">
                <Link className="default-btn" to={"/account"}>
                  <i className="bx bxs-right-arrow-circle"></i>
                  Go to Account Page
                  <span></span>
                </Link>
              </div>
              {/*<LicenseInfo*/}
              {/*  user={authUser}*/}
              {/*  customerInfo={this.state.customerInfo}*/}
              {/*/>*/}
              {/*<p>*/}
              {/*  You can access these keys at any time in your{" "}*/}
              {/*  <Link to={"/account"}>Account Page</Link>.*/}
              {/*</p>*/}
              <p>
                We'll send you an email confirmation and receipt to the email
                you've provided. If you don't receive it, please check your spam
                and junk folders, then contact us.
              </p>
            </div>
          )}
        </AuthUserContext.Consumer>
      </Fragment>
    )
  }
}

const condition = authUser => !!authUser

const ProtectedThankYouPage = compose(withAuthorization(condition, "thankyou"))(
  ThankYouPageBase
)

const ThankYouPage = location => {
  return (
    <Layout title="Thank You" description="Thank you for purchasing! view your license information and download the software here.">
      <section className="free-trial-area pb-100">
        <div className="container">
          <div className="free-trial-content">
            <ProtectedThankYouPage location={location} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ThankYouPage
